@use "../variables.scss" as *;

.hypecast-secondary-button {
  font-family: $font-family-bold;
  color: '#090909';
  // font-size: 20px;
  line-height: 100%;
  white-space: nowrap;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 15px 30px;

  cursor: pointer;

  border: 2px solid #090909;

  &--disabled {
    border: 2px solid #a3a3a3;
    color: #a3a3a3;
    cursor: default;
  }


  box-sizing: border-box;
  border-radius: 4px;

  position: relative;
  transition: opacity 0.3s linear;
}

.hypecast-secondary-button:hover {
  border-color: #0029FF;
}

.hypecast-secondary-button--disabled:hover {
  border-color: #a3a3a3;
}

.hypecast-secondary-button-small {
  font-family: $font-family-bold;
  color: '#090909';
  // font-size: 20px;
  line-height: 100%;
  white-space: nowrap;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px 15px;

  cursor: pointer;

  border: 1.5px solid #090909;
  box-sizing: border-box;
  border-radius: 4px;

  position: relative;
  transition: opacity 0.3s linear;
}

.hypecast-secondary-button-small:hover {
  border-color: #0029FF;
}
