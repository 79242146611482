@use "../variables.scss" as *;

.hypecast-search input {
  font-size: 1.125rem !important;
  line-height: 1.75rem !important;
}

.hypecast-search .mat-mdc-form-field-infix {
  padding: 15px 15px !important;
}

.hypecast-search .mat-mdc-form-field-label {
  padding: 0.25rem 15px;
  font-size: 1.125rem !important;
  line-height: 1.75rem !important;
}

.hypecast-search .mat-mdc-form-field-suffix {
  margin-right: 10px;
}

.mdc-text-field--outlined .mat-mdc-form-field-infix, .mdc-text-field--no-label .mat-mdc-form-field-infix {
  display: flex;
  justify-content: flex-end;
}

.hypecast-search .mat-mdc-text-field-wrapper {
  padding-left: 0px;
  padding-right: 0px;
}

.hypecast-search .mat-mdc-form-field-focus-overlay {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  opacity: 100;
  pointer-events: none;
  background-color: white !important;
}

.hypecast-search .mdc-text-field--focused {
  opacity: 100;
  background-color: white !important;
}

.hypecast-search .mdc-text-field:hover {
  opacity: 100;
  background-color: white !important;
}
