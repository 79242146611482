$namespace: "hypecast";

/** BACKGROUND **/

$background-color-primary: #fcfcfc;
$background-color-sub: #f2f2f2;
$background-color-hover: #4d4d4d;
$background-color-disabled-default: #d3d3d3;
$background-color-inverse: #202020;

$border-color-primary: #090909;

/** FONT **/
$font-color-black: #090909;
$font-color-secondary-blue: #0029FF;

$font-color-primary: #202020;
$font-color-secondary: #444444;
$font-color-sub: #d2d2d2;
$font-color-hover: rgb(133, 133, 133);
$font-color-disabled-inverse: #f2f2f2;
$font-color-disabled-default: #d3d3d3;
$font-color-inverse: #ffffff;

$font-family-regular: "Spartan-Regular";
$font-family-medium: "Spartan-Medium";
$font-family-semibold: "Spartan-Semibold";
$font-family-bold: "Spartan-Bold";

/** H1 **/
$font-size-h1-desktop: 50px;
$font-size-h1-medium: 32px;
$font-size-h1-mobile: 32px;
$line-height-h1-desktop: 120%;
$line-height-h1-medium: 42px;
$line-height-h1-mobile: 42px;
$letter-spacing-h1-desktop: 0.6px;
$letter-spacing-h1-medium: 0.6px;
$letter-spacing-h1-mobile: 0.6px;

/** H2 **/
$font-size-h2-desktop: 39px;
$font-size-h2-medium: 22px;
$font-size-h2-mobile: 22px;
$line-height-h2-desktop: 140%;
$line-height-h2-medium: 32px;
$line-height-h2-mobile: 32px;
$letter-spacing-h2-desktop: 0.6px;
$letter-spacing-h2-medium: 0.6px;
$letter-spacing-h2-mobile: 0.6px;

/** H3 **/
$font-size-h3-desktop: 25px;
$font-size-h3-medium: 18px;
$font-size-h3-mobile: 18px;
$line-height-h3-desktop: 120%;
$line-height-h3-medium: 28px;
$line-height-h3-mobile: 28px;
$letter-spacing-h3-desktop: 0.6px;
$letter-spacing-h3-medium: 0.6px;
$letter-spacing-h3-mobile: 0.6px;

/** H4 **/
$font-size-h4-desktop: 12px;
$font-size-h4-medium: 12px;
$font-size-h4-mobile: 12px;
$line-height-h4-desktop: 22px;
$line-height-h4-medium: 22px;
$line-height-h4-mobile: 22px;
$letter-spacing-h4-desktop: 2px;
$letter-spacing-h4-medium: 2px;
$letter-spacing-h4-mobile: 2px;

/** P **/
$font-size-p-desktop: 1.125rem;
$font-size-p-medium: 1.125rem;
$font-size-p-mobile: 1.125rem;
$line-height-p-desktop: 155%;
$line-height-p-medium: 1.75rem;
$line-height-p-mobile: 1.75rem;
$letter-spacing-p-desktop: 0.4px;
$letter-spacing-p-medium: 0.4px;
$letter-spacing-p-mobile: 0.4px;

/** P-SMALL **/
$font-size-p-small-desktop: 18px;
$font-size-p-small-medium: 13px;
$font-size-p-small-mobile: 13px;
$line-height-p-small-desktop: 155%;
$line-height-p-small-medium: 23px;
$line-height-p-small-mobile: 23px;
$letter-spacing-p-small-desktop: 0.4px;
$letter-spacing-p-small-medium: 0.4px;
$letter-spacing-p-small-mobile: 0.4px;

/** FOOT NOTE **/
$font-size-foot-note-desktop: 12px;
$font-size-foot-note-medium: 12px;
$font-size-foot-note-mobile: 12px;
$line-height-foot-note-desktop: 22px;
$line-height-foot-note-medium: 22px;
$line-height-foot-note-mobile: 22px;
$letter-spacing-foot-note-desktop: 0.4px;
$letter-spacing-foot-note-medium: 0.4px;
$letter-spacing-foot-note-mobile: 0.4px;

/** MEDIA QUERIES **/

/* GREATER THAN (min-width) */
$media-query-lt-sm: 599px;
$media-query-lt-md: 959px;
$media-query-lt-lg: 1279px;
$media-query-lt-xl: 1919px;

/* GREATER THAN (min-width) */
$media-query-gt-xs: 600px;
$media-query-gt-sm: 960px;
$media-query-gt-md: 1280px;
$media-query-gt-lg: 1920px;

/*** TABLE ***/
$table-view-height: calc(100vh - 253px);

/** ENTITIES **/
$podcast-color: #96af08;
