@use "../variables.scss" as *;

.hypecast-form-field input {
  font-size: 1.125rem !important;
  line-height: 1.75rem !important;
}

.hypecast-form-field textarea {
  font-size: 1.125rem !important;
  line-height: 1.75rem !important;
}

.hypecast-form-field .mat-mdc-form-field-infix {
  background-color: #f6feff !important;
  padding: 15px 25px !important;
}

.hypecast-form-field .mat-input-element {
  border-radius: 4px 4px 0px 0px !important;
  background-color: #f6feff !important;
}

.hypecast-form-field .mat-mdc-form-field-infix .mat-mdc-form-field-label-wrapper {
  top: -1.8rem !important;
  padding-top: 1.8rem !important;
  overflow: visible !important;
}

.hypecast-form-field .mat-mdc-form-field-label {
  background-color: white !important;
  color: #090909;
  transform: translateY(-1.8rem) scale(1) !important;

  font-family: $font-family-bold !important;
  font-size: 1.125rem !important;
  line-height: 1.75rem !important;
}

.hypecast-form-field .mat-mdc-form-field-focus-overlay {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  opacity: 100;
  pointer-events: none;
  background-color: white !important;
}

.hypecast-form-field .mdc-text-field--focused {
  opacity: 100;
  background-color: white !important;
}

.hypecast-form-field .mdc-text-field:hover {
  opacity: 100;
  background-color: white !important;
}


.hypecast-form-field .mat-mdc-text-field-wrapper {
  padding-left: 0px;
  padding-right: 0px;
}

.hypecast-form-field .mdc-text-field__input {
  padding-top: 8px;
}

.hypecast-form-field .mat-mdc-select-trigger {
  padding-top: 8px;
}
