.hypecast-double-tiles .selectable-tile {
    border-color: #090909;
    padding: 0.75rem;
}
.hypecast-double-tiles > :first-child .selectable-tile {
    border-top-left-radius: 999px;
    border-bottom-left-radius: 999px;
    background: white;
    border-right: none;
}
.hypecast-double-tiles > :last-child .selectable-tile {
    border-top-right-radius: 999px;
    border-bottom-right-radius: 999px;
    background: white;
}
.hypecast-double-tiles .selectable-tile > div {
    width: 100%;
    border-radius: 999px;
    align-items: center;
    justify-content: center;
}
.hypecast-double-tiles .selectable-tile.selected > div {
    background: #0029ff;
}