.hypecast-table .mat-mdc-header-row {
  background-color: #090909;
  z-index: 0;
  position: relative;
}

.hypecast-table .mat-mdc-header-row .mat-mdc-header-cell {
  color: white;
  font-size: 1rem;
  line-height: 1.5rem;
}

.hypecast-table .mat-mdc-sort-header-arrow {
  color: white !important;
}

.hypecast-table .mat-mdc-cell {
  font-size: 1.125rem;
  line-height: 1.75rem;
  color: #090909;
  white-space: nowrap;
  border-color: #090909;
  padding-top: 10px !important;
  padding-bottom: 10px;
}

.hypecast-table .mat-mdc-cell div {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.hypecast-table .mat-mdc-row {
  height: 75px !important;
}

.hypecast-table .mat-mdc-row:hover .mat-mdc-cell {
  background-color: white;
}
